import { useLiveQuery } from "dexie-react-hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "services/auth/AuthProvider";
import { db } from "services/db";
import { PeriodDateRange } from "views/trader/trades/variables/PeriodDateRange";
import { PeriodTradesArray } from "views/trader/trades/variables/PeriodTrades";
import { ProcessStats } from "views/trader/trades/variables/StatProcessor";
import { InstrumentsList } from 'views/trader/trades/variables/instruments';
import { Account, AccountGroup, Instrument, Mentee, Mentor, MentorMessage, Mistake, newAccountGroup, newInstrument, newStats, NotesTemplate, PeriodDates, PeriodTrades, Statistics, Strategy, Trade } from "views/trader/trades/variables/types";

const DataContext = createContext({} as any);

export const DataProvider = (props: { children: any }) => {

    const { user } = useAuth();

    const [usertrades, setUserTrades] = useState<Trade[]>([]);
    const [dateformat, setDateFormat] = useState(localStorage.getItem("dateformat") ? localStorage.getItem("dateformat") : "YYYY-MM-DD HH:mm")
    const [tradesFilterGroupAccount, setTradesFilterGroupAccount] = useState<Trade[]>([]);
    const [periodtrades, setPeriodTrades] = useState<Trade[]>([]);
    const [tradesflagged, setTradesFlagged] = useState<Trade[]>([]);
    const [period, setPeriod] = useState('all');
    const [statistics, setStatistics] = useState<Statistics>(newStats());
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountgroups, setAccountgroups] = useState<AccountGroup[]>([]);
    const [instruments, setInstruments] = useState<Instrument[]>([]);
    const [userinstruments, setUserInstruments] = useState<Instrument[]>([]);
    const [journals, setJournals] = useState([]);
    const [groupAccounts, setGroupAccounts] = useState<Account[]>([]);
    const [defaultaccount, setDefaultAccount] = useState<Account>(null);
    const [defaultgroup, setDefaultGroup] = useState<AccountGroup>(newAccountGroup());
    const [defaultcurrency, setDefaultCurrency] = useState(localStorage.getItem("currency") ? localStorage.getItem("currency") : 'USD');
    const [strategies, setStrategies] = useState<Strategy[]>([]);
    const [mistakes, setMistakes] = useState<Mistake[]>([]);
    const [mentors, setMentors] = useState<Mentor[]>([]);
    const [mentees, setMentees] = useState<Mentee[]>([]);
    const [mentormessages, setmentormessages] = useState<MentorMessage[]>([]);
    const [usertemplates, setusertemplates] = useState<NotesTemplate[]>([]);


    useLiveQuery(async () => {
        if (user) {
            await db.trades.where({ userid: user.id }).toArray().then((tds) => {
                    if (tds) {
                        tds.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()); // sort from most recent first
                        setUserTrades(tds);
                    }
            }).catch(() => { });
        }
    }, [user])

    useLiveQuery(async () => {
        await db.statistics.toArray().then((s) => {
            setStatistics(s.length > 0 ? s[0] : newStats());
            setPeriod(s.length ? s[0].period : 'all');
        })
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.strategies.where({ userid: user.id }).toArray().then((strats) => {
                setStrategies(strats);
            }).catch(error => { })
        }
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.mistakes.where({ userid: user.id }).toArray().then((mists) => {
                setMistakes(mists);
            }).catch(error => { })
        }
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.journals.where({ userid: user.id }).toArray((journals) => {
                return journals.map(journal => ({
                    id: journal.id,
                    date: journal.date,
                    title: journal.title,
                    category: journal.category,
                    flagged: journal.flagged,
                    published: false
                }));
            }).then(async (res) => {
                setJournals(res);
            }).catch(error => { })
        }
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.templates.where({ userid: user.id }).toArray().then((temps) => {
                setusertemplates(temps);
            }).catch(error => { })
        }
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.mentors.where({ userid: user.id }).toArray().then((m) => {
                setMentors(m);
            }).catch(error => { })
        }
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.mentees.where({ userid: user.id }).toArray().then((m) => {
                setMentees(m);
            }).catch(error => { })
        }
    }, [user])

    useLiveQuery(async () => {
        await db.mentormessages.orderBy('id').reverse().toArray().then((ms) => {
            setmentormessages(ms);
        })
    }, [user])

    useLiveQuery(async () => {
        if (user) {
            await db.instruments.where({ userid: user.id }).toArray().then((ins) => {
                setUserInstruments(ins);
            }).catch(() => { })
        }
    }, [user])

    useLiveQuery(async () => {
        await db.instruments.orderBy('relevance').reverse().toArray().then((insts) => {
            setInstruments(insts);
        }).catch(error => { })
    }, [user])

    useEffect(() => {
        if (!instruments.length) {
            addInstuments().then((instret) => {
                setInstruments(instret)
            })
        }
    }, [instruments])

    async function addInstuments(): Promise<Instrument[]> {
        return new Promise(async (resolve, reject) => {
            let insts: Instrument[] = [];
            for (var inst of InstrumentsList) {
                const newInst = newInstrument(inst);
                insts.push(newInst);
            }
            await db.instruments.bulkPut(insts).then(() => {
                resolve(insts);
            }).catch(() => {
                resolve(insts);
            })
        })
    }

    useLiveQuery(async () => {
        if (user) {
            await db.accountgroups.where({ userid: user.id }).toArray().then(async (grps) => {
                if (grps) {
                    setAccountgroups(grps);
                    const grp = grps.filter((g) => g.defaultgrp);
                    setDefaultGroup(grp.length ? grp[0] : newAccountGroup());

                    if (grp.length) {
                        await db.accounts.where({ userid: user.id }).toArray().then((accs) => {
                            if (accs) {
                                setAccounts(accs);
                                let grpaccs = accs.filter((a) => a.groupid === grp[0].groupid);
                                setGroupAccounts(grpaccs);
                                let defacc = grpaccs.filter((a) => a.defaultacc);
                                setDefaultAccount(defacc.length ? defacc[0] : null);

                                setDefaultCurrency(defacc.length ? defacc[0].currency : 'USD')
                            }
                        })
                    }
                }
            }).catch(() => { });
        }
    }, [user])

    useEffect(() => {
        if (defaultgroup) {
            setTradesFilterGroupAccount(defaultaccount ? usertrades.filter((t) => t.accountid === defaultaccount.accountid) : usertrades.filter((t) => t.groupid === defaultgroup.groupid));
        }
        else {
            setTradesFilterGroupAccount([]);
        }
    }, [defaultaccount, defaultgroup, usertrades])

    const updateFlaggedTrades = (flagtype: string) => {
        switch (flagtype) {
            case 'Reviewed':
                setTradesFlagged(tradesFilterGroupAccount.filter((tr) => tr.reviewed));
                break;
            case 'Flagged':
                setTradesFlagged(tradesFilterGroupAccount.filter((tr) => tr.flagged));
                break;
        }
    }

    useEffect(() => {
        let p: PeriodDates = PeriodDateRange(period);
        let t: PeriodTrades = PeriodTradesArray(tradesFilterGroupAccount, period, p);
        setPeriodTrades(t.current);
        updateStats();
    }, [period, tradesFilterGroupAccount]);

    useEffect(() => {
        let flag = localStorage.getItem('dashflag') ? localStorage.getItem('dashflag') : 'Flagged';
        updateFlaggedTrades(flag);
        updateStats();
    }, [tradesFilterGroupAccount]);

    const updateStats = async () => {
        if (defaultaccount) {
            await ProcessStats(tradesFilterGroupAccount, period, defaultaccount.currency);
            localStorage.setItem("currency", defaultaccount.currency);
        }
        else if (defaultgroup) {
            await ProcessStats(tradesFilterGroupAccount, period, defaultgroup.currency);
            localStorage.setItem("currency", defaultgroup.currency);
        }
        else {
            let newStat = newStats();
            await db.statistics.put(newStat, newStat.id).catch(error => { });
        }
    }

    const updatedateformat = (dformat: string) => {
        setDateFormat(dformat);
        localStorage.setItem("dateformat", dformat);
    }

    return (
        <DataContext.Provider value={{ usertrades, usertemplates, periodtrades, tradesflagged, statistics, accounts, defaultaccount, accountgroups, defaultgroup, groupAccounts, defaultcurrency, updateFlaggedTrades, period, setPeriod, dateformat, updatedateformat, instruments, userinstruments, journals, strategies, mistakes, mentors, mentees, mentormessages }}>
            {props.children}
        </DataContext.Provider>
    );

}

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error("useData error");
    }
    return context;
};