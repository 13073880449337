/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
																																																																																	   
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, useToast } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import api from 'services/api';
import { useAuth } from 'services/auth/AuthProvider';
import { JAUser, SubscriptionProduct } from '../trades/variables/types';
import TableCard from '../trades/components/tables/TableCard';
import UserTable from './components/UserTable';
import { UserSearch } from './components/UserSearchBar';

// Custom components

export default function Default() {

	const { user, signout } = useAuth();
	const [isadmin, setisadmin] = useState(false);
	const [traders, setTraders] = useState<JAUser[]>([]);
	const [products, setProducts] = useState<SubscriptionProduct[]>([]);
	const [loading, setloading] = useState(false);
	const [tablefilter, setTableFilter] = useState('');
	const toast = useToast();

	useEffect(() => {
		if (user.role === 'ADMIN') {
			api.post('/admin/checkpermission').then(async (res) => {
				setisadmin(res.data);
				if (res.data === false) {
					signout();
				}
				else {
					setloading(true);
					await api.post('/admin/gettable').then(async (res) => {
						setTraders(res.data.users);
						setProducts(res.data.products);
					}).catch(async (error) => {
						toast({ title: 'Error fetching', description: "", status: 'error', position: 'top', duration: 2000, isClosable: false })
					});
					setloading(false);
				}
			}).catch(async (error) => {
				signout();
			});
		}
		else {
			signout();
		}

	}, []);

	const searchtable = (e: ChangeEvent<HTMLInputElement>) => {
		setTableFilter(e.target.value.toLowerCase());
		//alert(e.target.value);
	}


	return (
		<>
			{isadmin &&
				<Flex direction={'column'} pt={{ base: '130px', md: '80px', xl: '80px' }} maxH={'100%'}>
					<TableCard title='Users' table={<UserTable users={traders} products={products} filter={tablefilter} />} button={<UserSearch onchange={searchtable} />} showTitleBar={true} showBg={true} showMt={false} showPadding={true} loading={loading} minh={'auto'} />
				</Flex>}
		</>
	);
}
