import { Flex, FormControl, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, useColorModeValue } from "@chakra-ui/react";
import { useState, useEffect, ChangeEvent } from "react";
import { currencyformat } from "views/trader/trades/variables/FormatData";

export const TradeTableCell = (props: { getValue: any, row: any, column: any, table: any }) => {

    const initialValue = props.getValue();
    const columnMeta = props.column.columnDef.meta;
    const colname: string = props.column.columnDef.meta.name;
    const currency: string = props.column.columnDef.meta.currency;
    const tableMeta = props.table.options.meta;
    const [value, setValue] = useState(initialValue);
    const initError = (initialValue === "" || initialValue === null);
    const [isNumberError, setIsNumberError] = useState(initError);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        tableMeta?.updateData(props.row.index, props.column.id, value, e.target.validity.valid);
        setIsNumberError(!e.target.validity.valid);
    };

    if (tableMeta?.editedRows[props.row.id]) {
        return <Flex>
            <FormControl isInvalid={isNumberError}>
                <NumberInput variant="outline" allowMouseWheel={true} onChange={(valueString) => setValue(Number(valueString))} value={value} step={columnMeta?.step} min={columnMeta?.min} onBlur={onBlur} isRequired={columnMeta?.required}>
                    <NumberInputField color={textColorPrimary} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>
        </Flex>
    }
    return <Flex>
        <Text color={textColorPrimary} fontSize='sm' fontWeight='600'>{colname === 'fee' ? currencyformat(value, (currency ? currency : 'USD')) : value}</Text>
    </Flex>;
};

