
import {
    useDisclosure,
    Button,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useColorModeValue,
    Flex,
    InputGroup,
    InputLeftAddon,
    Input,
    Text,
    InputRightAddon,
    Box,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useToast,
    Heading,
    Badge
}
    from "@chakra-ui/react";
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from "react";

import { MdClose } from 'react-icons/md';
import { db } from "services/db";
import { Account, Instrument, StrategyRule, Trade, TradeStrategy } from "../../variables/types";
import DeleteTradeConfirm from "../../../components/confirm/DeleteTradeConfirm";
import { currencyformat, getDuration, numberColor } from '../../variables/FormatData';
import Card from "components/card/Card";
import MistakeTags from "../fields/MistakeTags";
import TradeModalExecutionsTable from "./components/TradeModalExecutionsTable";
import StrategyTags from "../fields/StrategyTags";
import StrategyRuleSelect from "../fields/StrategyRuleSelect";
import ImageDropzone from "../fields/ImageDropzone";
import FullScreenButton from "views/trader/components/FullScreenButton";
import NotesTemplates from "../fields/NotesTemplates";
import RuleScore from "../fields/RuleScore";
import dayjs from "dayjs";
import JornoQuill from "views/trader/components/inputs/JornoQuill";
import useTrade from "../../hooks/useNewTrade";
import useNewAccount from "../../hooks/useNewAccount";
import Favourite from "views/trader/components/inputs/Favourite";
import { useAuth } from "services/auth/AuthProvider";
import JornoQuillReadOnly from "views/trader/components/inputs/JornoQuillReadOnly";
import RequestReview from "../fields/RequestReview";
import StudentShare from "../fields/StudentShare";
import { useData } from "services/data/DataProvider";

const TradeModal = forwardRef((props: { savetrade?: any, addUpdatedStrategy: any, addUpdatedMistake: any, setpendingupdate?: any, bulkupdate: boolean, updateSandMonly?: any, removefromtable?: any }, ref) => {

    const { setpendingupdate, bulkupdate, savetrade, addUpdatedStrategy, addUpdatedMistake, updateSandMonly, removefromtable } = props;

    const { user } = useAuth();
    const { dateformat } = useData();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [processing, setprocessing] = useState(false);
    const ModalBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('gray.700', 'white');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const headcol = useColorModeValue('gray.400', 'gray.500');
    const bgcolor = useColorModeValue('white', 'navy.800');
    const [trade, setTrade] = useState<Trade>(useTrade());
    const [account, setAccount] = useState<Account>(useNewAccount());
    const [instrument, setInstrument] = useState<Instrument>(null);
    const [tradeStrategies, setTradeStrategies] = useState<{ id: number, name: string, rules: StrategyRule[] }[]>([]);
    const [unusedStrategies, setUnusedStrategies] = useState<{ id: number, name: string, rules: StrategyRule[] }[]>([]);
    const [tradeMistakes, setTradeMistakes] = useState([]);
    const [unusedMistakes, setUnusedMistakes] = useState<any[]>([]);
    const [tradeflag, settradeflag] = useState(false);
    const [tradeshare, settradeshare] = useState(false);
    const [notes, setNotes] = useState("");
    const [mentornotes, setMentornotes] = useState("");
    const [stoploss, setStoploss] = useState(null);
    const [target, setTarget] = useState(null);
    const [points, setPoints] = useState('');
    const [targetpoints, setTargetPoints] = useState('');
    const [rvalue, setRvalue] = useState('');
    const [targetRvalue, setTargetRvalue] = useState('');
    const [rvaluecol, setRvaluecol] = useState('grey.200');
    const [targetrvaluecol, setTragetRvaluecol] = useState('grey.200');
    const [exchange, setExchange] = useState(1);
    const [baseamount, setBaseamount] = useState("");
    const [nettPL, setnettPL] = useState(0);
    const [hideExchange, setHideExchange] = useState(true);
    const [usedStrategyRules, setUsedStrategyRules] = useState<StrategyRule[]>([]);
    const [ruleshidden, setRuleshidden] = useState(true);
    const [rulescore, setRulescore] = useState(0);
    const [image, setImage] = useState<string>("");
    const toast = useToast();
    const [isMentored, setIsMentored] = useState(false);
    const [reviewrequested, setreviewrequested] = useState(false);
    const [reviewed, setreviewed] = useState(false);

    useImperativeHandle(ref, () => ({
        async openModal(trade: Trade) {

            await updateVariables(trade);
            onOpen();

            await db.mentors.where({ userid: user.id, status: 'active' }).toArray().then((r) => {
                setIsMentored(r.length > 0);
            }).catch(() => { })
        }
    }));

    const updateVariables = async (tr: Trade) => {

        await db.instruments.get({ ticker: tr.ticker }).then(async (inst) => {
            setInstrument(inst);
        });
        await db.accounts.get(tr.accountid).then((acc) => {
            setAccount(acc);
            setTrade(tr);
            setnettPL(tr.nett);
            setTradeStrategies(tr.strategies);
            updateUsedStrategyRules(tr.strategies);
            setRulescore(tr.rulescore);
            setTradeMistakes(tr.mistakes);
            setNotes(tr.notes);
            setMentornotes(tr.mentornotes);
            setStoploss(tr.stop_price === 0 ? null : tr.stop_price);
            setTarget(tr.target_price === 0 ? null : tr.target_price);
            setHideExchange(tr.currency === acc.currency);
            updateExchange(tr.exchange, tr.nett);
            setImage(tr.base64Image);
            settradeflag(tr.flagged);
            setreviewrequested(tr.req_review);
            setreviewed(tr.reviewed);
            settradeshare(user.role === "MENTOR" ? tr.shared : false);
            updateRvalue(tr);
            updateTargetRvalue(tr);
            setPoints(String(tr.points));
            let tpoints = tr.target_price > 0 ? Math.abs((tr.target_price - tr.entry_avg) * tr.size) : 0;
            setTargetPoints(tr.target_price === 0 ? '' : String(tpoints));
        })

        await db.strategies.where({ userid: user.id }).toArray().then(async (strats) => {
            for (let strat of strats) {
                if (!tr.strategies.filter((s) => s.id === strat.id).length) {
                    if (!unusedStrategies.filter((un) => un.id === strat.id).length) {
                        for (let r of strat.rules) {
                            if (r.used === undefined) {
                                r.used = false;
                            }
                        }
                        let s2: TradeStrategy = { id: strat.id, name: strat.name, rules: strat.rules }
                        unusedStrategies.push(s2);
                        setUnusedStrategies(unusedStrategies);
                    }
                }
            }
            await db.mistakes.where({ userid: user.id }).toArray().then(async (mistakes) => {
                for (let m of mistakes) {
                    if (!tr.mistakes.some((s: { name: string, id: number }) => s.id === m.id)) {
                        if (!unusedMistakes.some((un) => un.id === m.id)) {
                            let mi: { name: string, id: number } = { name: m.name, id: m.id };
                            unusedMistakes.push(mi);
                            setUnusedMistakes(unusedMistakes);
                        }
                    }
                }
            });
        });
    }

    const updateStrategies = async (used: TradeStrategy[], unused: TradeStrategy[]) => {
        setTradeStrategies(used);

        await db.strategies.where({ userid: user.id }).toArray().then((strats) => {
            unused.forEach((str, index) => {
                let s = strats.find((st) => st.id === str.id);
                let s2: TradeStrategy = { id: s.id, name: s.name, rules: s.rules }
                unused = [...unused.slice(0, index), s2, ...unused.slice(index + 1)];
            });
            setUnusedStrategies(unused);
        })


        trade.strategies = used;
        setTrade(trade);
        updateUsedStrategyRules(used);
        //alert("USED: " + JSON.stringify(used) + " UNUSED: " + JSON.stringify(unused));
    }

    const updateUsedStrategyRules = async (used: TradeStrategy[]) => {

        usedStrategyRules.splice(0, usedStrategyRules.length);// empty array first

        let r1: StrategyRule[] = [];

        for (let strat of used) {
            for (let rule of strat.rules) {
                r1.push(rule);
            }
        }

        updateStrategyRules(r1);
        setRuleshidden(!r1.length);
    }

    const ignoreRule = (id: number, sid: number) => {

        let e = usedStrategyRules.slice();
        e.forEach((r, index) => {
            if (r.sid === sid && r.id === id) {
                e.splice(index, 1);
            }
        })
        updateStrategyRules(e);

        trade.strategies.forEach((str) => {
            if (str.id === sid) {
                str.rules.forEach((r, index) => {
                    if (r.id === id) {
                        str.rules.splice(index, 1);
                    }
                })
            }
        })
    }

    const useRule = (e: ChangeEvent<HTMLInputElement>, rule: StrategyRule) => {
        rule.used = e.target.checked;
        const update = usedStrategyRules.map(oldrule => (oldrule.id === rule.id && oldrule.sid === rule.sid) ? rule : oldrule);
        updateStrategyRules(update);
    }

    const updateStrategyRules = (arr: any[]) => {
        setUsedStrategyRules(arr);
        updateRuleScore(arr);
    }

    const updateRuleScore = (update: any[]) => {
        let u = update.filter((r) => r.used);
        let score = (update.length ? ((u.length / update.length) * 100) : 0)
        setRulescore(score);
        trade.rulescore = Number(score.toFixed());
    }

    const updatemistakes = (used: [], unused: []) => {
        setTradeMistakes(used);
        setUnusedMistakes(unused);
        trade.mistakes.splice(0, trade.mistakes.length)
        for (let m of used) {
            trade.mistakes.push(m);
        }
        setTrade(trade);
    }

    const addStratToUpdate = (s: TradeStrategy) => {
        addUpdatedStrategy(s);
    }

    const addmisttoupdate = (m: { name: string; id: number }) => {
        addUpdatedMistake(m);
    }
    const addnewsavedmistake = (m: { name: string; id: number }) => {
        setUnusedMistakes([...unusedMistakes, m]);
    }

    const closeTrade = () => {
        setprocessing(false);
        setUnusedMistakes([]);
        onClose();
    }

    function formatCurrencyAmount(amt: number, currency: string) {
        return currencyformat(amt, currency);
    }

    const updateStop = (stop: number) => {
        setStoploss(stop === 0 ? null : stop);
        trade.stop_price = stop;
        updateRvalue(trade);
        updateTargetRvalue(trade);

    }
    const updateTarget = (target: number) => {
        setTarget(target === 0 ? null : target);
        trade.target_price = target;
        updateTargetRvalue(trade);
        let sell = (trade.entry_avg - target) * trade.size;
        let buy = (target - trade.entry_avg) * trade.size;
        let tpoints = trade.side === 'sell' ? sell : buy;//Math.abs(target - trade.entry_avg) * trade.size;
        setTargetPoints(target === 0 ? '' : String(tpoints));
    }

    const updateRvalue = (tr: Trade) => {
        if (tr.stop_price > 0) {
            let stop_points = Math.abs(tr.entry_avg - tr.stop_price);
            let R = (tr.points / tr.size) / stop_points;
            trade.rvalue = Number(R.toFixed(2));
            setRvaluecol(R > 0 ? (R < 1 ? 'orange.500' : 'green.500') : 'red.500');
            setRvalue(R.toFixed(2) + ' R')
        }
        else {
            setRvalue('');
            trade.rvalue = 0;
        }
    }

    const updateTargetRvalue = (tr: Trade) => {
        if (tr.target_price > 0 && tr.stop_price > 0) {
            let stop_points = Math.abs(tr.entry_avg - tr.stop_price);
            let sell = (tr.entry_avg - tr.target_price) ;
            let buy = (tr.target_price - tr.entry_avg) ;
            let target_points = trade.side === 'sell' ? sell : buy;//Math.abs(tr.target_price - tr.entry_avg);
            let R = (target_points / tr.size) / (stop_points / tr.size);
            trade.target_rvalue = Number(R.toFixed(2));
            setTragetRvaluecol(R > 0 ? (R < 1 ? 'orange.500' : 'green.500') : 'red.500');
            setTargetRvalue(R.toFixed(2) + ' R');
            setTargetPoints(String(target_points));
        }
        else {
            setTargetRvalue('');
            trade.target_rvalue = 0;
        }
    }

    const setTradeNotes = (content: string) => {//e: ChangeEvent<HTMLTextAreaElement>) => {
        updateTradeNotes(content);
    }

    const updateTradeNotes = (text: string) => {
        setNotes(text);
        trade.notes = text;
    }

    const updatetrade = () => {
        let fees = 0;
        for (let ex of trade.executions) {
            fees += ex.fee;
        }
        trade.fees = fees;
        trade.nett = trade.profitloss - fees;
        setnettPL(trade.nett);
        updateExchange(exchange, trade.nett);
    }

    const updateExchange = (rate: number, nettpl?: number) => { // optional nettpl if lagging nettPL state not set yet
        setExchange(rate);
        trade.exchange = rate;
        let base = formatCurrencyAmount(((nettpl ? nettpl : nettPL) / rate), account.currency);
        setBaseamount(base);
    }

    const updateimage = async (file: string) => {
        setImage(file);
        trade.base64Image = file;
    }

    const updateTrade = () => {

        if (!hideExchange && exchange === 0) {
            toast({ title: 'Exchange rate needed ...', description: "", status: 'error', position: 'top', duration: 3000, isClosable: false });
            return;
        }

        if (bulkupdate === true) {
            setpendingupdate(trade);
            closeTrade();
        }
        else {
            closeTrade();
            savetrade(trade);
        }
    }

    const updateSandM = () => {
        onClose();
        if (bulkupdate === false) {
            updateSandMonly();
        }
    }

    const deletefromtable = (tradeid: number) => {
        try {
            if (removefromtable) {
                removefromtable(tradeid);
            }
        }
        catch (e) { }
        closeTrade();
    }

    const updatefavourite = (fav: boolean) => {
        settradeflag(fav);
        trade.flagged = fav;
    }

    const updatereq = (req: false) => {
        setreviewrequested(req);
        trade.req_review = req;
    }
    const updateShare = (share: boolean) => {
        settradeshare(user.role === "MENTOR" ? share : false);
        trade.shared = share;
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={closeTrade} size='full' autoFocus={false} returnFocusOnClose={false} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent bg={ModalBg}>
                    <ModalCloseButton onClick={updateSandM} isDisabled={processing} />
                    <FullScreenButton />
                    <ModalHeader>

                        <Flex direction={'row'}>
                            <Flex me='20px'>
                                <Favourite selected={tradeflag} updatefavourite={updatefavourite} />
                            </Flex>
                            <Text mt='5px'>Trade Details</Text>
                        </Flex>

                    </ModalHeader>
                    <ModalBody>
                        <Flex justify="space-between" direction={{ base: 'column', md: 'row' }}>
                            <Box>
                                <InputGroup size='md'>
                                    <InputLeftAddon color='white' bg={numberColor(trade ? trade.nett : 0)} fontWeight={'700'}>
                                        {formatCurrencyAmount(nettPL, trade.currency)}
                                    </InputLeftAddon>
                                    <InputLeftAddon borderLeftRadius='0px'>
                                        {trade.ticker}
                                    </InputLeftAddon>
                                    <Input placeholder='trade date ...' defaultValue={trade ? dayjs(trade.datetime).format(dateformat) : ""} color={textColor} isReadOnly={true} />
                                    <InputRightAddon>
                                        {account && account.accname}
                                    </InputRightAddon>
                                </InputGroup>
                            </Box>
                            <Flex justify={'space-between'} >
                                <Text color='secondaryGray.500' fontSize='md' fontWeight='600' mt={{ base: '20px', md: '8px' }} ms={{ base: '0px', md: '20px' }}>
                                    {"Duration: " + getDuration(trade.duration)}
                                </Text>
                                {reviewed &&
                                    <Flex align={'center'} mt={{ base: '15px', md: '0px' }} ms='20px'>
                                        <Badge size={'sm'} variant='outline' borderRadius={'5px'} colorScheme='green' fontSize={'sm'}>
                                            MENTOR REVIEWED
                                        </Badge>
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                        <Grid mt='30px' templateRows='repeat(1, 1fr)' templateColumns={{ md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
                            templateAreas={{
                                base: `'image' 'stops' 'details' 'notes' 'executions' `,
                                //md: `'details' 'notes' 'executions' 'image'`,
                                lg: `'stops image' 'details image' 'notes image' 'executions image'`
                            }}
                            gap={5}
                        >
                            <Card gridArea='stops' borderColor={bdColor} borderWidth='1px'>
                                <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} maxW={'750px'}>
                                    <Box justifyContent={'center'}>
                                        <Flex direction={'row'} justify={'space-between'} gap={5}>
                                            <FormControl>
                                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>STOP price</FormLabel>
                                                <NumberInput min={0} allowMouseWheel={true} step={(instrument ? instrument.ticksize : 1)} onChange={(e) => updateStop(Number(e))} defaultValue={stoploss} >
                                                    <NumberInputField color={textColor} />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>TARGET price</FormLabel>
                                                <NumberInput min={0} allowMouseWheel={true} step={(instrument ? instrument.ticksize : 1)} onChange={(e) => updateTarget(Number(e))} defaultValue={target} >
                                                    <NumberInputField color={textColor} />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </FormControl>
                                        </Flex>

                                        <Flex direction={{ base: 'column', md: 'row' }} justify={'space-between'} mt='30px' gap={5}>
                                            <Flex direction={'column'}>
                                                <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' py='15px'>
                                                    <Flex><Heading fontWeight='bold' size={'xs'} mt='-25px' ms='-11px' bg={bgcolor} px='10px' py='0px'>Points (total)</Heading></Flex>
                                                    <Flex direction={'row'} justify={'space-between'} gap={2}>
                                                        <Flex direction={'column'} >
                                                            <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Target</FormLabel>
                                                            <Input fontWeight={'bold'} color={Number(targetpoints) < 0 ? 'red.500' : 'green.500'} isReadOnly={true} value={targetpoints}></Input>
                                                        </Flex>
                                                        <Flex direction={'column'} >
                                                            <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Realised</FormLabel>
                                                            <Input fontWeight={'bold'} color={Number(points) < 0 ? 'red.500' : 'green.500'} isReadOnly={true} value={points}></Input>
                                                        </Flex>
                                                    </Flex>
                                                </Card>
                                            </Flex>
                                            <Flex direction={'column'}>
                                                <Card borderColor={bdColor} borderWidth='1px' h={'100%'} bg='transparent' py='15px'>
                                                    <Flex><Heading fontWeight='bold' size={'xs'} mt='-25px' ms='-11px' bg={bgcolor} px='10px'>R-Value</Heading></Flex>
                                                    <Flex direction={'row'} justify={'space-between'} gap={2}>
                                                        <Flex direction={'column'}>
                                                            <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Target</FormLabel>
                                                            <Input fontWeight={'bold'} color={targetrvaluecol} isReadOnly={true} value={targetRvalue}></Input>
                                                        </Flex>
                                                        <Flex direction={'column'}>
                                                            <FormLabel fontWeight='bold' fontSize='sm' color={'gray.500'}>Realised</FormLabel>
                                                            <Input fontWeight={'bold'} color={rvaluecol} isReadOnly={true} value={rvalue}></Input>
                                                        </Flex>
                                                    </Flex>
                                                </Card>
                                            </Flex>
                                        </Flex>

                                    </Box>
                                    <Box justifyContent={'center'} hidden={hideExchange}>
                                        <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Base conversion</FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon color='white' bg={numberColor(trade ? trade.nett : 0)} fontWeight={'700'}>
                                                {baseamount}
                                            </InputLeftAddon>
                                            <NumberInput min={0} allowMouseWheel={true} step={0.001} defaultValue={exchange} onChange={(e) => updateExchange(Number(e))} isInvalid={exchange === 0}>
                                                <NumberInputField color={textColor} borderLeftRadius={'0px'} />
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper />
                                                    <NumberDecrementStepper />
                                                </NumberInputStepper>
                                            </NumberInput>
                                        </InputGroup>
                                    </Box>
                                </Flex>
                            </Card>
                            <Card gridArea='details' borderColor={bdColor} borderWidth='1px'>
                                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={3}>
                                    <GridItem w='100%' colSpan={1}>
                                        <StrategyTags
                                            id='strategies'
                                            label='Strategies'
                                            mb='0px'
                                            h='auto'
                                            usedstrategies={tradeStrategies}
                                            unusedstrategies={unusedStrategies}
                                            updatestrategytags={updateStrategies}
                                            notifychanges={addStratToUpdate}
                                        />
                                        <Flex hidden={ruleshidden} direction={'column'}>
                                            <Flex direction={'row'} justify={'space-between'}>
                                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Rules followed</FormLabel>
                                                <RuleScore rulescore={rulescore} width={'30%'} size={'lg'} />
                                            </Flex>
                                            <Card borderColor={bdColor} borderWidth='1px' borderRadius={'10px'} mb={'20px'}>
                                                {usedStrategyRules.map((rule, index) => (
                                                    <StrategyRuleSelect rule={rule} key={index} ignoreRule={ignoreRule} useRule={useRule}></StrategyRuleSelect>
                                                ))}
                                            </Card>
                                        </Flex>
                                    </GridItem>
                                    <GridItem w='100%' colSpan={1}>
                                        <MistakeTags
                                            id='mistake'
                                            label='Mistakes'
                                            mb='0px'
                                            h='auto'
                                            usedMistakeTags={tradeMistakes}
                                            unusedMistakeTags={unusedMistakes}
                                            updatemistaketags={updatemistakes}
                                            tagtype={'mistakes'}
                                            addnewsavedmistake={addnewsavedmistake}
                                            notifychanges={addmisttoupdate}
                                        />
                                    </GridItem>
                                </Grid>

                            </Card>
                            <Card gridArea='image' borderColor={bdColor} borderWidth='1px' >
                                <Flex direction={'row'} justify={'space-between'}>
                                    <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Image</FormLabel>
                                    <Flex hidden={!image.length} w="30px" h="30px" justify="center" align="center" bg={'red.500'} borderColor={'red.500'} borderWidth={'2px'} borderRadius="10px" position="absolute" top="15px" right="15px" transition="0.3s" cursor="pointer" onClick={() => updateimage('')} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                                        <Icon as={MdClose} color="#fff" fontSize="20px" />
                                    </Flex>
                                </Flex>
                                <ImageDropzone imagefile={image} setimagefile={updateimage} />
                            </Card>
                            <Card gridArea='notes' borderColor={bdColor} borderWidth='1px' >
                                <Flex direction={'row'} justify={'space-between'}>
                                    <Flex direction={'row'}>
                                        <FormLabel fontWeight='bold' fontSize='sm' mt='5px'>Notes</FormLabel>
                                    </Flex>
                                    <NotesTemplates type="trade" notes={notes} setnotes={updateTradeNotes} reset={''} mb={'8px'} size={'sm'} />
                                </Flex>
                                <Box>
                                    <JornoQuill content={notes} updatecontent={setTradeNotes} allowimages={false} placeHolder={'Enter trade notes ...'} />
                                </Box>

                                {mentornotes.trim().length > 0 &&
                                    <>
                                        <FormLabel fontWeight='bold' fontSize='sm' mt='20px'>Mentor Notes</FormLabel>
                                        <Box>
                                            {mentornotes.trim().length ? (
                                                <JornoQuillReadOnly content={mentornotes} placeHolder={'No notes entered by mentor'} />
                                            ) : (
                                                <Heading size={'xs'} color={headcol}>No notes entered by mentor</Heading>
                                            )}
                                        </Box>
                                    </>
                                }

                            </Card>
                            <Card gridArea='executions' borderColor={bdColor} borderWidth='1px' >
                                <FormLabel fontWeight='bold' fontSize='sm' mb='8px'>Executions</FormLabel>
                                <TradeModalExecutionsTable trade={trade} instrument={instrument} updateTrade={updatetrade}></TradeModalExecutionsTable>
                            </Card>
                        </Grid>

                    </ModalBody>
                    <ModalFooter>
                        <Flex direction={{ base: 'column', md: 'row' }} >
                            {isMentored &&
                                <RequestReview checked={reviewrequested} updaterequest={updatereq} tradeid={trade.tradeid} disabled={trade.reviewed} />
                            }
                            {user.role === 'MENTOR' &&
                                <Flex mt='20px'>
                                    <StudentShare checked={tradeshare} updateshare={updateShare} label="Share with students" />
                                </Flex>
                            }
                            <Flex direction={'row'} justify={'end'} mt='20px'>
                                <DeleteTradeConfirm id={trade.tradeid} hidden={processing} closemodal={deletefromtable} />
                                <Button colorScheme='blue' ms={3} onClick={updateTrade} isLoading={processing} loadingText='Saving'>
                                    Save
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
});

export default TradeModal;
