// Chakra imports
import { Badge, List, ListItem, ListIcon, Text, Button, useColorModeValue, useToast } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
// Assets
import { BsCircleFill } from 'react-icons/bs';
import { useAuth } from 'services/auth/AuthProvider';
import { db } from 'services/db';
import ManageSubscription from './ManageSubscriptionModal';

export default function PackSubscription(props: {
	title: string;
	button: string
	price: JSX.Element | string;
	details: string;
	monthly: boolean;
	benefits: string[];
	dosubscription: any,
	updatepayment: any,
	changebilling: any,
	openportal: any,
	highlighted?: boolean;
}) {
	//const navigate = useNavigate();

	const { title, button, price, details, monthly, benefits, dosubscription, highlighted, updatepayment, changebilling, openportal } = props;
	const { user } = useAuth();

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('gray.400', 'gray.400');
	const yearlyColor = useColorModeValue('cyan.400', 'cyan');

	const [btntext, setBtntxt] = useState("Subscribe");
	//const [btndisabled, setBtndisabled] = useState(false);
	const [isActive, setIsActive] = useState(button === user.role);
	const [btnColor, setBtnColor] = useState('');
	const [isUpgrade, setIsUpgrade] = useState(true); // ie will clicking upgrade or downgrade
	const toast = useToast();

	useEffect(() => {
		setBtntxt(button === user.role ? 'ACTIVE' : getBtnText(user.role))
	}, [])

	const getBtnText = (role: string) => {
		let txt = 'Subscribe';
		switch (role) {
			case 'FREE':
				txt = "Upgrade";
				setBtnColor('green');
				break;
			case 'NOVICE':
				txt = (button === "PRO" || button === "MENTOR" ? 'Upgrade' : 'Downgrade')
				setBtnColor(button === "PRO" || button === "MENTOR" ? 'green' : 'red');
				setIsUpgrade(button === "PRO" || button === "MENTOR");
				break;
			case 'PRO':
				txt = (button === "MENTOR" ? 'Upgrade' : 'Downgrade')
				setBtnColor(button === "MENTOR" ? 'green' : 'red');
				setIsUpgrade(button === "MENTOR");
				break;
			case 'MENTOR':
				txt = "Downgrade";
				setBtnColor('red');
				setIsUpgrade(false);
				break;
		}
		return txt;
	}

	const toastwarning = (sub: string, accs: number, grps: number, ments: number) => {
		let title = "";
		let a_to_delete = "";
		let g_to_delete = "";
		let m_to_delete = "";
		let endtxt = "";
		switch (sub) {
			/*case 'FREE':
				title = "\nTo downgrade to FREE, please:\n";
				m_to_delete = (ments ? "\nDELETE - " + ments + " mentorship(s)" : "");
				a_to_delete = (accs - 1 > 0 ? "\nDELETE - " + (accs - 1) + " trading account(s)" : "");
				g_to_delete = (grps - 1 > 0 ? "\nDELETE - " + (grps - 1) + " trading group(s)" : "");
				endtxt = grps - 1 > 0 ? "\n\nNOTE: you can move accounts between groups" : "";
				break;*/
			case 'NOVICE':
				title = "\nTo downgrade to NOVICE, please:\n";
				m_to_delete = (ments ? "\nDELETE - " + ments + " mentorship(s)" : "");
				a_to_delete = (accs - 5 > 0 ? "\nDELETE - " + (accs - 5) + " trading account(s)" : "");
				g_to_delete = (grps - 2 > 0 ? "\nDELETE - " + (grps - 2) + " trading group(s)" : "");
				endtxt = grps - 2 > 0 ? "\n\nNOTE: you can move accounts between groups" : "";
				break;
			case 'PRO':
				title = "\nTo downgrade to PRO, please:\n";
				m_to_delete = (ments ? "\nDELETE - " + ments + " mentorship(s)" : "");
				a_to_delete = (accs - 20 > 0 ? "\nDELETE - " + (accs - 20) + " trading account(s)" : "");
				g_to_delete = (grps - 5 > 0 ? "\nDELETE - " + (grps - 5) + " trading group(s)" : "");
				endtxt = grps - 5 > 0 ? "\n\nNOTE: you can move accounts between groups" : "";
				break;
		}

		toast({ title: 'Please cleanup first!', description: <Text whiteSpace="pre-line">{title + m_to_delete + a_to_delete + g_to_delete + endtxt}</Text>, status: 'error', position: 'top', duration: null, isClosable: true })
	}

	const updateSubscription = async () => {

		await db.mentors.where({ userid: user.id }).count().then(async (mentors) => {
			await db.mentees.where({ userid: user.id }).count().then(async (mentees) => {
				let mentorships = mentors + mentees;
				await db.accounts.where({ userid: user.id }).count().then(async (accs) => {
					await db.accountgroups.where({ userid: user.id }).count().then((grps) => {
						switch (button) {
							case 'FREE':
								if (isUpgrade) {
									dosubscription(isUpgrade, "FREE");
								}
								else {
									if (accs > 1 || grps > 1 || mentorships > 0) {
										toast({ title: 'Downgrade to FREE', description: <Text whiteSpace="pre-line">{"\nPlease cancel your subscription by clicking the ACTIVE button.\n\nYou're back on the FREE plan automatically when it expires.\n\n"}</Text>, status: 'info', position: 'top', duration: 7000, isClosable: true })
										//toastwarning("FREE", accs, grps, mentorships);
									}
									else {
										dosubscription(isUpgrade, "FREE");
									}
								}
								break;
							case 'NOVICE':
								if (isUpgrade) {
									dosubscription(isUpgrade, "NOVICE");
								}
								else {
									if (accs > 5 || grps > 2 || (user.role === "MENTOR" && mentorships > 0)) {
										toastwarning("NOVICE", accs, grps, (user.role === "MENTOR" ? mentorships : 0))
									}
									else {
										dosubscription(isUpgrade, "NOVICE");
									}
								}
								break;
							case 'PRO':
								if (isUpgrade) {
									dosubscription(isUpgrade, "PRO");
								}
								else {
									if (accs > 20 || grps > 5 || (user.role === "MENTOR" && mentorships > 0)) {
										toastwarning("PRO", accs, grps, (user.role === "MENTOR" ? mentorships : 0))
									}
									else {
										dosubscription(isUpgrade, "PRO");
									}
								}
								break;
							case 'MENTOR':
								dosubscription(isUpgrade, "MENTOR");
								break;
						}
					})
				})

			})
		})




		//navigate("/auth/signup")

	}

	return (
		<Card
			p='20px'
			pb='45px'
			pt={highlighted ? '60px' : '30px'}
			w={{ sm: '375px', md: '360px', lg: '360px' }}
			borderWidth={highlighted ? '1px' : "0px"}
			alignItems='flex-start'
			justifyContent='flex-start'
			overflow='unset !important'>
			<Badge
				display={highlighted ? 'block' : 'none'}
				w='max-content'
				position='absolute'
				fontSize='sm'
				color='teal.500'
				bg='teal.100'
				fontWeight='bold'
				textTransform='unset'
				left='50%'
				borderRadius='70px'
				transform='translate(-50%,-250%)'>
				BEST VALUE
			</Badge>
			<Text fontSize='30px' color={textColor} fontWeight='700' mb='30px'>
				{title}
			</Text>
			{isActive ? (
				<>
					{user.role === 'FREE' ? (
						<Button w='100%' variant={props.highlighted ? 'brand' : 'outline'} colorScheme={btnColor} mb='30px' isDisabled={true}>
							FREE ACTIVE
						</Button>
					) :
						(
							<ManageSubscription highlighted={highlighted} btnColor={btnColor} updatepayment={updatepayment} changeBilling={changebilling} gotoportal={openportal}/>
						)}
				</>
			) : (
				<Button w='100%' variant={highlighted ? 'brand' : 'outline'} colorScheme={btnColor} mb='30px' onClick={updateSubscription} isDisabled={user.role === 'ADMIN'}>
					{btntext}
				</Button>

			)}

			{price}
			<Text fontSize='md' color={monthly? 'secondaryGray.600': yearlyColor} fontWeight='700' mt='5px'>
				{details}
			</Text>
			<List spacing={3} justifyContent='flex-start' >
				{benefits.map((benefit, index) => (
					<ListItem
						key={index}
						display='flex'
						textAlign='start'
						fontSize='md'
						fontWeight='500'
						color={textColorSecondary}
						alignItems='center'
						lineHeight='100%'
						mt='30px !important'>
						<ListIcon w='10px' h='10px' as={BsCircleFill} my='auto' color={textColor} />
						{benefit}
					</ListItem>
				))}{' '}
			</List>
		</Card>
	);
}
