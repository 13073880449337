import { Drawer, DrawerContent, Image, DrawerOverlay, useDisclosure, Icon, Flex } from "@chakra-ui/react"
import Card from "components/card/Card";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { MdClose } from "react-icons/md";
import FullScreenButton from "views/trader/components/FullScreenButton";



const ImageDrawer = forwardRef((props: { imageurl: any }, ref) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    interface ImageDimensions {
        width: number;
        height: number;
    }

    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null)

    useEffect(() => {
        const handleResize = () => {
            updateImageSize();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useImperativeHandle(ref, () => ({
        openImage() {
            onOpen();
            updateImageSize();
        }
    }));

    const closeDrawer = () => {
        onClose();
    }

    const updateImageSize = () => {
        getWindowDimensions().then((win: ImageDimensions) => {

            getImageDimensions().then((res: ImageDimensions) => {

                let vratio = win.height / res.height;
                let hratio = win.width / res.width;

                if (res.height > res.width) {
                    setHeight(win.height);
                    setWidth(res.width * vratio);
                }
                else {
                    setHeight(win.height);
                    setWidth(res.width * hratio);
                }
            })
        })

    }

    const getWindowDimensions = (): Promise<ImageDimensions> => {
        return new Promise((resolve, reject) => {
            const { innerWidth: width, innerHeight: height } = window;
            resolve({ width: width, height: height });
        });
    };

    const getImageDimensions = (): Promise<ImageDimensions> => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img') as HTMLImageElement; // Create image element
            img.src = props.imageurl;
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    return <>
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            size='full' placement='top'
        >
            <DrawerOverlay />
            <DrawerContent >
                <Flex autoFocus zIndex={1000} w="30px" h="30px" justify="center" align="center" bg={'red.500'} borderColor={'red.500'} borderWidth={'2px'} borderRadius="10px" position="absolute" top="10px" right="15px" transition="0.3s" cursor="pointer" onClick={() => closeDrawer()} _hover={{ background: 'rgba(0, 0, 0, 0.25);' }}>
                    <Icon as={MdClose} color="#fff" fontSize="20px" />
                </Flex>
                <FullScreenButton/>
 
                <Flex justify={'center'} align={'center'}>
                    <Card w={width} h={height} p='0px' borderRadius={'0px'}>
                        <Image src={props.imageurl} w='100%' h='100%' />
                    </Card>
                </Flex>

            </DrawerContent>
        </Drawer>
    </>
});

export default ImageDrawer


