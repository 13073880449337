import { DayNote } from "../variables/types";
import dayjs from "dayjs";
import { useAuth } from "services/auth/AuthProvider";


const useNewDaynote = () => {

    const { user, muuid } = useAuth();//useContext(AuthContext);

    const newtemp: DayNote = {
		id: 0,
		date: dayjs().format('YYYY-MM-DD'),
		userid: (user === null ? 0 : user.id),
		groupid: 0,
		text: '',
		bias: '',
		trades: [],
		last_uuid: muuid,
		sync_due: false
	}
	return newtemp;
};

export default useNewDaynote;