import { Button, Flex, FormControl, FormLabel, Icon, Switch, useColorModeValue, Spinner, Box } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';


import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DashCalEvent, newDashCalEvent, Trade } from 'views/trader/trades/variables/types';
import { currencyformat } from 'views/trader/trades/variables/FormatData';
import DashCalendarModal from 'views/trader/dashboard/components/calendar/DashCalendarModal';
import { useData } from 'services/data/DataProvider';
import { useLoading } from 'services/loading/LoadingProvider';

export default function DashCalendar() {
    // Chakra Color Mode
    dayjs.extend(isBetween);
    const {loading} = useLoading();
    const { periodtrades, defaultcurrency } = useData();

    const spinnerColor = useColorModeValue('gray.300', 'brand.300');
    const bdColor = useColorModeValue('gray.200', 'gray.700');
    const calRef = useRef(null);
    const [showweekends, setshowweekends] = useState(localStorage.getItem("weekends") ? JSON.parse(localStorage.getItem("weekends")) : false);
    const [calTitle, setCalTitle] = useState("");
    const [calData, setCalData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [periodtrades])

    const fetchData = async () => {
        updateEvents();
    }
    const updateEvents = () => {

        setshowweekends(localStorage.getItem("weekends") ? JSON.parse(localStorage.getItem("weekends")) : false);

        return new Promise(async (resolve) => {

            if (!periodtrades.length) {
                try { // need catch incase getApi() null
                    //remove existing events from calendar
                    let evts = calRef.current.getApi().getEvents();
                    for (let ev of evts) {
                        ev.remove();
                    }
                }
                catch (e) { }
            }


            calData.length = 0; //.splice(0, calData.length);

            const arr: { date: string, trades: Trade[] }[] = [];

            //collect trades into date object arrays
            for (let tr of periodtrades) {

                let i = arr.findIndex((a) => a.date === dayjs(tr.datetime).format('YYYY-MM-DD'))

                if (i < 0) {
                    let ar = [tr];
                    arr.push({ date: dayjs(tr.datetime).format('YYYY-MM-DD'), trades: ar });
                }
                else {
                    arr[i].trades.push(tr);
                }
            }

            // generate calendar events
            const arr2: DashCalEvent[] = [];
            for (let ev of arr) {

                let day = dayjs(ev.date).day();
                //alert("DAY: " + day + " date: " + dayjs(ev.date).format('YYYY-MM-DD'))
                if (day === 0 || day === 6) {
                    setshowweekends(true);
                }

                let evt: DashCalEvent = newDashCalEvent();
                evt.start = ev.date;
                evt.end = dayjs(ev.date).add(1, 'day').format('YYYY-MM-DD');

                let total = 0;
                let riskreward = 0;
                let rrtrades = 0;

                for (let t of ev.trades) {

                    if (t.stop_price > 0) {
                        riskreward += ((t.points / t.size) / Math.abs(t.entry_avg - t.stop_price))
                        rrtrades++;
                    }

                    let ex = t.currency !== defaultcurrency ? t.exchange : 1;
                    total += (t.nett / ex);
                    evt.extendedProps.trades.push(t.tradeid);
                    evt.extendedProps.groupid = t.groupid;
                }

                evt.extendedProps.avgR = (rrtrades > 0 ? riskreward / rrtrades : 0);
                //alert(evt.extendedProps.avgR);

                evt.backgroundColor = (total > 0 ? '#01B574' : '#EE5D50');
                evt.title = currencyformat(total, defaultcurrency);//total.toFixed(2);
                arr2.push(evt);
            }

            if (periodtrades.length) {
                //setValidrange({ start: dayjs(trades[0].datetime).format('YYYY-MM-DD'), end: dayjs(trades[trades.length - 1].datetime).add(1, 'days').format('YYYY-MM-DD') });
                calRef.current.getApi().gotoDate(dayjs(periodtrades[periodtrades.length - 1].datetime).format("YYYY-MM-DD"));
                setCalTitle(dayjs(periodtrades[periodtrades.length - 1].datetime).format('MMMM') + " " + dayjs(periodtrades[periodtrades.length - 1].datetime).get('year'));
            }
            else {
                //setValidrange({ start: '', end: '' });
            }

            setCalData([...calData, ...arr2]);

            resolve('');
        });
    }
    const updateWeekends = (e: ChangeEvent<HTMLInputElement>) => {
        setshowweekends(e.target.checked);
        localStorage.setItem('weekends', JSON.stringify(e.target.checked));
    }
    const calforward = () => {
        calRef.current.getApi().next();
        setCalTitle(calRef.current.getApi().view.title);
    }
    const calback = () => {
        calRef.current.getApi().prev();
        setCalTitle(calRef.current.getApi().view.title);
    }
    const lastTrade = () => {
        try {
            let evts = calRef.current.getApi().getEvents();
            calRef.current.getApi().gotoDate(evts[evts.length - 1].start);//data.length ? data[data.length - 1].date[0] : dayjs().format("YYYY-MM-DD"))
        }
        catch (err) { }
        setCalTitle(calRef.current.getApi().view.title);
    }
    function renderEventContent(ev: any) {
        return (
            <DashCalendarModal event={ev} />
        )
    }

    return (
        <Card h='100%' minH={'800px'} p='20px' borderColor={bdColor} borderWidth='1px' bg='transparent'>
            <Flex direction={'row'} justify={'space-between'} mb='20px'>
                <Flex direction={'row'} mt='5px'>
                    <FormLabel fontWeight='bold' fontSize='md' mb='8px'>Daily Totals</FormLabel>
                    <FormLabel fontWeight='normal' fontSize='md' mb='8px'  color='secondaryGray.600'>{calTitle}</FormLabel>
                </Flex>
                <Flex direction={{ base: 'column', md: 'row' }}>
                    <Flex>
                        <FormControl display='flex' alignItems='center' ms='10px' mb={{ base: '10px', md: '0px' }}>
                            <Switch id='weekends' onChange={updateWeekends} isChecked={showweekends} />
                            <FormLabel htmlFor='weekends' mb='0' ms='10px'>
                                Weekends
                            </FormLabel>
                        </FormControl>
                    </Flex>
                    <Flex direction={'row'}>
                        <Button variant={'outline'} size={'md'} borderRadius='5px' me='5px' onClick={lastTrade}>
                            Last
                        </Button>
                        <Button variant={'outline'} p='0px' size={'md'} borderRadius='5px' onClick={calback}>
                            <Icon as={MdChevronLeft} boxSize={5} />
                        </Button>
                        <Button variant={'outline'} p='0px' size={'md'} borderRadius='5px' ms='5px' onClick={calforward}>
                            <Icon as={MdChevronRight} boxSize={5} />
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            <Flex h='100%' align={'center'} justify={'center'} hidden={!loading}>
                <Spinner size={'lg'} thickness='4px' speed='0.65s' color={spinnerColor}></Spinner>
            </Flex>
            <Box h='100%' w='100%' hidden={loading}>
                <FullCalendar
                    ref={calRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={false}
                    initialView='dayGridMonth'
                    contentHeight='100%'
                    events={calData}
                    initialDate={dayjs().format('YYYY-MM-DD')}
                    editable={false}
                    height='100%'
                    weekends={showweekends}
                    eventContent={renderEventContent}
                    //validRange={validRange}
                />
            </Box>
        </Card>
    );
}